import {useEffect, useState} from 'react';
import {
  get,
  last,
  filter,
  isNull,
  omitBy,
  isEmpty,
  orderBy,
  cloneDeep,
  isString,
  isArray,
  toLower,
} from 'lodash';
import Modal from 'react-modal';
import {nanoid} from 'nanoid';
import {Formik} from 'formik';
import MainForm from './MainForm';
import {ProductTag, Products} from '../Types';
import PriceForm from './PriceForm';
import Dropzone from 'react-dropzone';
import {Schema} from '../Validator';
import {connect} from 'react-redux';
import OptionForm from './OptionForm';
import ModifierForm from './ModifierForm';
import {AiOutlineClose} from 'react-icons/ai';
import S3Image from '../../../components/S3Image';
import {
  mapBrand,
  mapCategory,
  mapWarranty,
  productOptions,
} from '../../../internals/managers/ProductManager';
import AddBrand from '../../../components/AddBrand';
import {HiOutlineArrowLeft} from 'react-icons/hi';
import {Actions} from '../../../internals/app/Actions';
import AddCategory from '../../../components/AddCategory';
import ImageComponent from '../../../components/UploadImage';
import DeleteModal from '../../../components/Modal/DeleteModal';
import AddWarranty from '../../../components/Modal/AddWarranty';
import defaultImage from '../../../assets/images/default-store.jpeg';
import ImageRemoveIcon from '../../../assets/svgs/imageRemoveIcon.svg';
import ProductRepository from '../../../internals/repository/ProductRepository';

const customStyles: any = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    position: 'fixed',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  content: {
    padding: 0,
    height: 'auto',
    border: 'none',
    overflow: 'auto',
    maxWidth: '824px',
    background: 'none',
    left: 'calc(50vw - 492px)',
    WebkitOverflowScrolling: 'touch',
  },
};

type ProductProps = {
  tags: Array<ProductTag>;
  brands: any;
  product?: any;
  imageId: string;
  menuImage: string;
  isOpen: boolean;
  categories: any;
  warranties: any;
  isEdit?: boolean;
  currentUser: any;
  selectedShop: any;
  loadingAction: any;
  closeModal: () => void;
  removeImage: () => void;
  setImage: (params: any) => void;
  uploadImage: (params: any, type?: string) => void;
  updateProduct: (params: any) => void;
  createProduct: (params: any) => void;
  fetchProductOptionSets: (params: any) => void;
  deleteProduct: (productId: string, shopId: string) => void;
  fetchProductTags: (shopId: string) => void;
};

const Initials = {
  brand: '',
  warranty: {},
  category: '',
  modifiers: [],
  itemPrice: '',
  productName: '',
  description: '',
  priceVariants: [],
  productOptions: [],
};

const AddProduct = (props: ProductProps) => {
  const {
    tags,
    isOpen,
    brands,
    product,
    imageId,
    closeModal,
    categories,
    warranties,
    currentUser,
    removeImage,
    uploadImage,
    selectedShop,
    updateProduct,
    createProduct,
    deleteProduct,
    loadingAction,
    fetchProductTags,
    fetchProductOptionSets,
  } = props;
  const [menuImage, setMenuImage] = useState<any>();
  const [isUploading, setUploading] = useState<boolean>(false);
  const [productImages, setProductImages] = useState<Array<any>>([]);

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editModifier, setEditModifier] = useState<any>({});
  const [isOpenBrand, setIsOpenBrand] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);
  const [selectedWarranty, setSelectedWarranty] = useState<any>({});
  const [productInitial, setProductInitial] = useState<any>(Initials);
  const [isOpenCategory, setIsOpenCategory] = useState<boolean>(false);
  const [validationSchema, setValidationSchema] = useState<any>(Schema);
  const [openPriceVariant, setOpenPriceVariant] = useState<boolean>(false);
  const [openProductOption, setOpenProductOption] = useState<boolean>(false);
  const [openProductModifier, setOpenProductModifier] =
    useState<boolean>(false);
  const [openWarrantyModal, setOpenWarrantyModal] = useState<boolean>(false);
  const optionsInitial = [
    {
      id: nanoid(),
      optionUnit: '',
      optionName: '',
      optionPrice: 0,
    },
  ];

  const fetctOptionSet = async () => {
    const shopId = selectedShop?.shopId;
    if (shopId) {
      fetchProductOptionSets(shopId);
    }
  };

  useEffect(() => {
    if (selectedShop?.shopId) {
      fetchProductTags(selectedShop.shopId);
    }
  }, [selectedShop]);

  useEffect(() => {
    if (isOpen) {
      fetctOptionSet();
    }
  }, [isOpen]);

  useEffect(() => {
    const isValid = !isEmpty(product);
    setIsEdit(isValid);
    if (isValid) {
      let initial = {
        brand: product.brandId,
        category: product.categoryId,
        itemPrice: product.itemPrice,
        actualPrice: product.actualPrice,
        sendToBar: product.sendToBar,
        showMenu: product.showMenu,
        productName: product.productName,
        description: product.description,
        sendToKitchen: product.sendToKitchen,
        warranty: get(product, 'warranty', {}),
        modifiers: get(product, 'modifiers', []),
        priceVariants: get(product, 'priceVariants', []),
        productOptions: get(product, 'productOptions', []),
        tags: get(product, 'tags', []).reduce((acc: Array<any>, tag: string) => {
          const found = tags.find(({tagName}) => tagName === tag);
          console.log({found, tags, tag})
          if (found) {
            acc.push({
              value: found.tagId,
              label: found.tagName,
              shopId: found.shopId,
            });
          }
          return acc;
        }, []),
        stockCount: get(product, 'stockCount', 0),
      };
      if (!isEmpty(product?.productOptions)) {
        const updatedSchema = Schema.omit(['itemPrice']);
        setValidationSchema(updatedSchema);
      }
      setProductInitial({...initial});
      setSelectedProduct(product);
      setSelectedWarranty(get(product, 'warranty', {}));
      setMenuImage(product.menuImage);
      setProductImages(product.productImages || []);
    } else {
      setProductInitial({...Initials});
    }
  }, [isOpen, product]);

  useEffect(() => {
    return () => {
      setOpenPriceVariant(false);
      setOpenProductOption(false);
      setProductInitial({...Initials});
    };
  }, []);

  const formData = (values: any) => {
    const params: any = {
      brandId: values.brand,
      sendToBar: values.sendToBar,
      showMenu: values.showMenu,
      categoryId: values.category,
      description: values.description,
      productName: values.productName,
      searchName: toLower(`${values.productName}`),
      sendToKitchen: values.sendToKitchen,
      stockCount: values.stockCount,
    };
    if (isArray(values?.tags)) {
      params.tags = values.tags.map(({value, label, shopId}: any) => label);
    }
    if (values.slug) {
      params.slug = values.slug;
    }
    if (!isEmpty(values?.productOptions)) {
      params.priceVariants = values.priceVariants.map((priceVariant: any) => {
        const price = parseFloat(priceVariant.price);
        priceVariant.price = price;
        return priceVariant;
      });
      params.productOptions = [...values.productOptions];
    } else {
      params.itemPrice = parseFloat(values.itemPrice);
      if (!isNaN(values.actualPrice))
      params.actualPrice = parseFloat(values.actualPrice);
    }
    if (!isEmpty(values?.modifiers)) {
      const shopId = selectedShop.shopId;
      const modifiers = values.modifiers.map((modifier: any) => {
        const items = modifier.items.map((item: any) => {
          item['itemId'] = nanoid(8);
          item.price = parseFloat(item.price);
          return item;
        });
        const params = {
          shopId,
          items: items,
          id: modifier.id,
          title: modifier.title,
          setName: modifier.setName,
          isMandatory: modifier.isMandatory,
        };
        return params;
      });
      params.modifiers = modifiers;
    }
    if (!isEmpty(selectedWarranty)) {
      params['warranty'] = selectedWarranty;
    }
    return params;
  };

  const getMenuImagePath = async () => {
    if (menuImage?.path) {
      return await saveImage();
    }
    return menuImage;
  };

  const getProductImages = async () => {
    return await Promise.all(
      productImages.map(async (pi) => {
        console.log(pi);
        if (pi.path) {
          try {
            setUploading(true);
            // @ts-ignore
            return await ProductRepository.uploadProductImage(pi, 'menuImage');
          } catch (e) {
            console.log('Uploading error');
          } finally {
            setUploading(false);
          }
        }
        return pi;
      })
    );
  };

  const uploadProductOptionImages = async (productOptions: any) => {
    if (!isArray(productOptions)) {
      return [];
    }
    return await Promise.all(
      productOptions.map(async (item: any) => {
        item.options = await Promise.all(
          item.options?.map(async (option: any) => {
            if (option.optionImage?.path) {
              try {
                setUploading(true);
                const fileName = await ProductRepository.uploadProductImage(
                  option.optionImage,
                  'menuImage'
                );
                option.optionImage = fileName;
              } catch (e) {
                console.log('Uploading error');
              } finally {
                setUploading(false);
              }
            }

            console.log(option);
            return option;
          })
        );

        return item;
      })
    );
  };

  const onCreateProduct = async (values: any) => {
    const shopId = selectedShop.shopId;
    const formValues = formData(values);
    const menuImagePath = await getMenuImagePath();
    const productImages = await getProductImages();

    formValues.productOptions = await uploadProductOptionImages(
      formValues.productOptions
    );

    const param = {
      shopId,
      ...formValues,
      pid: nanoid(10),
      productImage: imageId,
      menuImage: menuImagePath,
      productImages,
      createdTime: Date.now(),
      updatedTime: Date.now(),
      createdUserId: currentUser.uid,
    };
    createProduct(param);
    closeModal();
  };

  const onUpdateProduct = async (values: any) => {
    const formValues = formData(values);
    const menuImagePath = await getMenuImagePath();
    const productImages = await getProductImages();

    formValues.productOptions = await uploadProductOptionImages(
      formValues.productOptions
    );

    const param = {
      ...formValues,
      pid: product.pid,
      productImage: imageId,
      menuImage: menuImagePath,
      productImages,
      shopId: product.shopId,
      updatedTime: Date.now(),
      createdTime: product.createdTime,
      createdUserId: product.createdUserId,
    };
    updateProduct(param);
    closeModal();
  };

  const setImageToUpload = async (acceptedFiles: any) => {
    setMenuImage(acceptedFiles[0]);
  };

  const saveImage = async () => {
    try {
      setUploading(true);
      // @ts-ignore
      return await ProductRepository.uploadProductImage(menuImage, 'menuImage');
    } catch (e) {
      console.log('Uploading error');
    }
    setUploading(false);
  };

  const onAddFile = async (acceptedFiles: any, type?: string) => {
    uploadImage(acceptedFiles[0], type);
  };

  const addProductOption = (status: boolean, values: any) => {
    const options = {
      id: nanoid(),
      setName: '',
      setDisplayName: '',
      options: optionsInitial,
      shopId: selectedShop.shopId,
    };
    if (isEmpty(values?.productOptions)) {
      values['productOptions'].push(options);
    }
    Schema.validate({itemPrice: 1});
    const updatedSchema = Schema.omit(['itemPrice']);
    setValidationSchema(updatedSchema);
    setOpenProductOption(status);
  };

  const validModifierItem = (data: any) => {
    const isItemValid = data.items.every(
      (item: any) => !isEmpty(item.itemName) && parseFloat(item.price) > 0
    );
    return !isEmpty(data?.setName) && !isEmpty(data?.title) && isItemValid;
  };

  const addProductModifier = (values: any, errors: any) => {
    let option: any = {
      title: '',
      setName: '',
      id: nanoid(8),
      isMandatory: false,
      items: [
        {
          price: 0,
          itemName: '',
        },
      ],
    };
    // if (isEmpty(values?.modifiers)) {
    //   values.modifiers.push(option);
    // } else {
    //   const lastData = last(values.modifiers);
    //   const isValid = validModifierItem(lastData);
    //   if (isValid) {
    //     values.modifiers.push(option);
    //   }
    // }
    values.modifiers.push(option);
    setOpenProductModifier(true);
  };

  const brandList = mapBrand(brands);
  const categoryList = mapCategory(categories);
  const warrantyList = mapWarranty(warranties);

  const onProductDelete = () => {
    deleteProduct(product.pid, product.shopId);
    setIsOpenDelete(false);
    closeModal();
  };

  const toggleBrandModal = () => {
    setIsOpenBrand(!isOpenBrand);
  };

  const toggleCategoryModal = () => {
    setIsOpenCategory(!isOpenCategory);
  };

  const setProductImagesToUploaded = async (acceptedFiles: any) => {
    setProductImages([...productImages, ...acceptedFiles]);
  };

  const removeProductImage = async (index: number) => {
    productImages.splice(index, 1);
    setProductImages([...productImages]);
  };

  const toggleWarrantyModal = () => {
    setOpenWarrantyModal(!openWarrantyModal);
  };

  const toggleProductOption = () => {
    setOpenProductOption(!openProductOption);
  };

  const togglePriceVariant = () => {
    setOpenPriceVariant(!openPriceVariant);
  };

  const toggleProductModifier = () => {
    setOpenProductModifier(!openProductModifier);
  };

  const onPressSubModalBack = () => {
    if (openProductOption) {
      toggleProductOption();
    } else if (openProductModifier) {
      setEditModifier({});
      toggleProductModifier();
    } else {
      togglePriceVariant();
      toggleProductOption();
    }
  };

  const getModalTitleStatus = (status: string) => {
    if (openProductOption) {
      return `${status} Options`;
    } else if (openPriceVariant) {
      return `${status} Price Variants`;
    } else if (openProductModifier) {
      if (!isEmpty(editModifier)) {
        return `Edit Modifier`;
      } else {
        return `Add Modifier`;
      }
    } else {
      return `${status} Product`;
    }
  };
  const getModalTitle = () => {
    let headerTitle: string = '';
    if (isEdit) {
      headerTitle = getModalTitleStatus('Edit');
    } else {
      headerTitle = getModalTitleStatus('Add');
    }
    return headerTitle;
  };

  const isSubModal =
    openProductOption || openPriceVariant || openProductModifier;

  const handleSubmitProduct = async (values: Products, {resetForm}: any) => {
    if (openProductModifier) {
      return setOpenProductModifier(false);
    }
    try {
      if (!openProductOption && !openProductModifier) {
        if (!isEdit) {
          await onCreateProduct(values);
        } else {
          await onUpdateProduct(values);
        }
        resetForm();
        setOpenPriceVariant(false);
      }
    } catch (error) {
      console.log('submit product error:', error);
    }
  };

  const renderButton = (props: any) => {
    const {createText, deleteText, onClickDelete} = props;
    return (
      <div className="flex flex-row justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-lg">
        {!isEmpty(product) ? (
          <button
            type="button"
            onClick={() => onClickDelete()}
            className="inline-flex outline-none justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-red-700"
          >
            {deleteText}
          </button>
        ) : (
          <button
            type="button"
            onClick={() => closeModal()}
            className="inline-flex outline-none justify-center rounded-md border border-gray-300 bg-ghost-white-2 py-2 px-4 text-sm font-medium text-valhalla shadow hover:bg-ghost-white"
          >
            {'Close'}
          </button>
        )}
        <button
          type="submit"
          className="inline-flex justify-center rounded-md border border-transparent bg-medium-slate-blue py-2 px-4 text-sm font-medium text-white shadow hover:bg-slate-blue"
        >
          {createText}
        </button>
      </div>
    );
  };

  const checkArrays = (arr1: any, arr2: any) => {
    if (arr1.length !== arr2.length) {
      return false;
    }
    const result = arr1.every((element: string, index: number) => {
      return element === arr2[index];
    });
    return result;
  };

  const generateCombinations = (arrays: any, values: any) => {
    const combinations: any = [];
    const backtrack = (startIndex: any, currentCombination: any) => {
      if (currentCombination.length === arrays.length) {
        combinations.push(currentCombination.slice());
        return;
      }
      const currentArray = arrays[startIndex];
      for (let i = 0; i < currentArray.length; i++) {
        currentCombination.push(currentArray[i]);
        backtrack(startIndex + 1, currentCombination);
        currentCombination.pop();
      }
    };
    backtrack(0, []);

    let variants = combinations.map((item: any) => {
      const optionUnit = item
        .map((option: any) => option.optionName)
        .join(' - ');
      const options = item.map((option: any) => option.id);
      let newVariants: any = {
        options,
        price: 0,
        optionUnit,
        sku: nanoid(6),
      };
      if (isEdit) {
        const priceValues = cloneDeep(values.priceVariants);
        priceValues.map((variant: any) => {
          const isEqueal = checkArrays(variant.options, options);
          if (isEqueal) {
            newVariants = {
              ...{
                sku: variant.sku,
                price: variant.price,
                options: variant.options,
                optionUnit: variant.optionUnit,
              },
            };
          }
        });
      }
      return newVariants;
    });
    return variants;
  };

  const addOptionVariants = (values: any, setFieldValue: any) => {
    let oprionsArray: any = [];
    values.productOptions.forEach((productOption: any) => {
      oprionsArray.push([...productOption.options]);
    });
    const priceVariants = generateCombinations(oprionsArray, values);
    const orderVariants = orderBy(priceVariants, ['optionUnit'], ['asc']);
    setFieldValue('priceVariants', [...orderVariants]);
    setOpenProductOption(false);
    setOpenPriceVariant(true);
  };

  const onCloseModal = () => {
    setOpenPriceVariant(false);
    setOpenProductOption(false);
    setOpenProductModifier(false);
    Schema.validate({itemPrice: 0});
    const updatedSchema = Schema.required('itemPrice');
    setValidationSchema(updatedSchema);
    setProductInitial({...Initials, productOptions: [], modifiers: []});
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Product Modal"
      // onRequestClose={() => onCloseModal()}
    >
      <div className="bg-white shadow rounded-lg">
        <div className="flex flex-row justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-lg">
          <div className="flex flex-row justify-start items-center">
            {isSubModal && (
              <button
                type="button"
                onClick={() => onPressSubModalBack()}
                className="z-10 top-3 mr-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
              >
                <HiOutlineArrowLeft size={20} />
                <span className="sr-only">Back modal</span>
              </button>
            )}
            <h3>{getModalTitle()}</h3>
          </div>

          <button
            type="button"
            onClick={() => onCloseModal()}
            className="z-10 top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
          >
            <AiOutlineClose size={20} />
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <div className={'overflow-auto relative max-h-[calc(100vh-136px)]'}>
          <Formik
            validateOnBlur={true}
            validateOnChange={true}
            enableReinitialize={true}
            onSubmit={handleSubmitProduct}
            initialValues={productInitial}
            validationSchema={validationSchema}
            validate={(values) => {
              const errors: any = {};
              if (!isEmpty(values.productOptions)) {
                errors.itemPrice = null;
              }
              return omitBy(errors, isNull);
            }}
          >
            {({errors, values, setErrors, handleSubmit, setFieldValue}) => {
              return (
                <form id="productForm" onSubmit={handleSubmit}>
                  {openProductOption ? (
                    <>
                      <OptionForm
                        product={selectedProduct}
                        productOptions={productInitial.productOptions}
                      />
                      <div className="flex flex-row justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-lg">
                        <button
                          type="button"
                          onClick={() => {
                            if (isEmpty(product)) {
                              setFieldValue(`productOptions`, []);
                            }
                            setOpenProductOption(false);
                          }}
                          className="inline-flex outline-none justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-red-700"
                        >
                          {'Remove All'}
                        </button>
                        <div
                          onClick={() => {
                            let prevErrors = {
                              ...errors,
                              itemPrice: undefined,
                            };
                            setErrors(prevErrors);
                            if (isEmpty(errors?.productOptions)) {
                              addOptionVariants(values, setFieldValue);
                            }
                          }}
                          className="cursor-pointer inline-flex justify-center rounded-md border border-transparent bg-medium-slate-blue py-2 px-4 text-sm font-medium text-white shadow hover:bg-slate-blue"
                        >
                          {isEdit ? 'Update Options' : 'Add Price Variations'}
                        </div>
                      </div>
                    </>
                  ) : openPriceVariant ? (
                    <>
                      <PriceForm
                        productOptions={productOptions}
                        addProductOption={() => addProductOption(true, values)}
                      />

                      <div className="flex flex-row justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-lg">
                        <div />
                        <div
                          className="cursor-pointer inline-flex justify-center rounded-md border border-transparent bg-medium-slate-blue py-2 px-4 text-sm font-medium text-white shadow hover:bg-slate-blue"
                          onClick={() => {
                            if (isEmpty(errors?.priceVariants)) {
                              setOpenPriceVariant(false);
                            }
                          }}
                        >
                          {isEdit ? 'Update Variations' : 'Create Variations'}
                        </div>
                      </div>
                    </>
                  ) : openProductModifier ? (
                    <>
                      <ModifierForm
                        product={selectedProduct}
                        editModifier={editModifier}
                        productModifier={productInitial.modifier}
                        removeEditModifier={() => setEditModifier({})}
                      />
                      <div className="flex flex-row justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-lg">
                        <button
                          type="button"
                          onClick={() => {
                            setEditModifier({});
                            if (isEmpty(product)) {
                              const filterModifiers = filter(
                                values.modifiers,
                                (modifier: any, index: number) =>
                                  index !== values.modifiers.length - 1
                              );

                              setFieldValue(`modifiers`, [...filterModifiers]);
                            }
                            setOpenProductModifier(false);
                          }}
                          className="inline-flex outline-none justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-red-700"
                        >
                          {'Cancel'}
                        </button>
                        <div
                          onClick={() => {
                            setEditModifier({});
                            if (isEmpty(errors?.modifiers)) {
                              setOpenProductModifier(false);
                            }
                          }}
                          className="cursor-pointer inline-flex justify-center rounded-md border border-transparent bg-medium-slate-blue py-2 px-4 text-sm font-medium text-white shadow hover:bg-slate-blue"
                        >
                          {isEdit ? 'Update Modifier' : 'Add Modifier'}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="overflow-auto bg-white px-4 py-5 sm:p-6">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-3 sm:col-span-3">
                            <label className="block text-sm font-medium text-gray-700">
                              Product Image
                            </label>
                            <div className="mt-1 flex items-start justify-start cursor-pointer">
                              {imageId ? (
                                <div className="w-auto flex relative flex-row h-48 rounded-lg border-dashed border border-sky-500">
                                  <S3Image
                                    url={imageId}
                                    className="w-auto rounded-lg"
                                    defaultImage={defaultImage}
                                  />
                                  <img
                                    alt="remove"
                                    src={ImageRemoveIcon}
                                    onClick={() => removeImage()}
                                    className="w-6 absolute right-2 top-2 cursor-pointer"
                                  />
                                </div>
                              ) : (
                                <Dropzone
                                  multiple={false}
                                  onDrop={(files) => onAddFile(files)}
                                  accept={{
                                    'image/png': ['.png', '.jpeg', '.JPG'],
                                  }}
                                >
                                  {({getRootProps, getInputProps}: any) => (
                                    <ImageComponent
                                      getRootProps={getRootProps}
                                      getInputProps={getInputProps}
                                      loadingAction={loadingAction}
                                    />
                                  )}
                                </Dropzone>
                              )}
                            </div>
                          </div>
                          <div className="col-span-3 sm:col-span-3">
                            <label className="block text-sm font-medium text-gray-700">
                              Menu Image
                            </label>
                            <div className="mt-1 flex items-start justify-start cursor-pointer">
                              <Dropzone
                                multiple={false}
                                onDrop={(files) => setImageToUpload(files)}
                                accept={{
                                  'image/png': ['.png', '.jpeg', '.JPG'],
                                }}
                              >
                                {({getRootProps, getInputProps}: any) => (
                                  <ImageComponent
                                    getRootProps={getRootProps}
                                    getInputProps={getInputProps}
                                    loadingAction={loadingAction}
                                  >
                                    {menuImage ? (
                                      <div className="w-auto flex relative flex-row h-48 rounded-lg">
                                        {isString(menuImage) ? (
                                          <S3Image
                                            type="menu"
                                            url={menuImage}
                                            className="w-auto rounded-lg"
                                            defaultImage={defaultImage}
                                          />
                                        ) : (
                                          <img
                                            src={URL.createObjectURL(menuImage)}
                                          />
                                        )}

                                        <img
                                          alt="remove"
                                          src={ImageRemoveIcon}
                                          onClick={() => removeImage()}
                                          className="w-6 absolute right-2 top-2 cursor-pointer"
                                        />
                                      </div>
                                    ) : null}
                                  </ImageComponent>
                                )}
                              </Dropzone>
                            </div>
                          </div>

                          <div className="col-span-6 sm:col-span-6">
                            <label className="block text-sm font-medium text-gray-700">
                              Product Images
                            </label>
                            <div className="grid grid-cols-6 gap-2">
                              {!isEmpty(productImages) ? (
                                productImages.map((productImage, index) => {
                                  return (
                                    <div className="mt-1 flex items-start justify-start cursor-pointer">
                                      <Dropzone
                                        multiple
                                        onDrop={(files) =>
                                          setProductImagesToUploaded(files)
                                        }
                                        accept={{
                                          'image/png': [
                                            '.png',
                                            '.jpeg',
                                            '.JPG',
                                          ],
                                        }}
                                      >
                                        {({
                                          getRootProps,
                                          getInputProps,
                                        }: any) => (
                                          <ImageComponent
                                            height="24"
                                            getRootProps={getRootProps}
                                            getInputProps={getInputProps}
                                            loadingAction={loadingAction}
                                          >
                                            <div className="w-auto flex relative flex-row h-24 rounded-lg">
                                              {isString(productImage) ? (
                                                <S3Image
                                                  type="menu"
                                                  url={productImage}
                                                  className="w-auto rounded-lg"
                                                  defaultImage={defaultImage}
                                                />
                                              ) : (
                                                <img
                                                  src={URL.createObjectURL(
                                                    productImage
                                                  )}
                                                />
                                              )}

                                              <img
                                                alt="remove"
                                                src={ImageRemoveIcon}
                                                onClick={(e) => {
                                                  removeProductImage(index);
                                                  e.stopPropagation();
                                                  e.preventDefault();
                                                }}
                                                className="w-6 absolute right-2 top-2 cursor-pointer"
                                              />
                                            </div>
                                          </ImageComponent>
                                        )}
                                      </Dropzone>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="mt-1 flex items-start justify-start cursor-pointer">
                                  <Dropzone
                                    multiple
                                    onDrop={(files) =>
                                      setProductImagesToUploaded(files)
                                    }
                                    accept={{
                                      'image/png': ['.png', '.jpeg', '.JPG'],
                                    }}
                                  >
                                    {({getRootProps, getInputProps}: any) => (
                                      <ImageComponent
                                        height="24"
                                        getRootProps={getRootProps}
                                        getInputProps={getInputProps}
                                        loadingAction={loadingAction}
                                      >
                                        <div className="w-auto flex relative flex-row h-24 rounded-lg">
                                          <img
                                            alt="remove"
                                            src={ImageRemoveIcon}
                                            onClick={() => removeImage()}
                                            className="w-6 absolute right-2 top-2 cursor-pointer"
                                          />
                                        </div>
                                      </ImageComponent>
                                    )}
                                  </Dropzone>
                                </div>
                              )}
                            </div>
                          </div>

                          <MainForm
                            brandList={brandList}
                            product={selectedProduct}
                            categoryList={categoryList}
                            warrantyList={warrantyList}
                            productOptions={productOptions}
                            openBrand={() => toggleBrandModal()}
                            openCategory={() => toggleCategoryModal()}
                            openWarranty={() => toggleWarrantyModal()}
                            addProductOption={() => {
                              addProductOption(true, values);
                            }}
                            addProductModifier={() => {
                              addProductModifier(values, errors);
                            }}
                            onSelectedModifier={(modifier: any) => {
                              setEditModifier(modifier);
                              setOpenProductModifier(true);
                            }}
                            onSelectWarranty={(warranty: any) => {
                              setSelectedWarranty(warranty);
                            }}
                            editPriceVarients={() => setOpenPriceVariant(true)}
                          />
                        </div>
                      </div>
                      {renderButton({
                        deleteText: 'Delete Product',
                        createText: isEdit ? 'Update Product' : 'Add Product',
                        onClickDelete: () => setIsOpenDelete(true),
                      })}
                    </>
                  )}
                </form>
              );
            }}
          </Formik>
        </div>
        <AddBrand isOpen={isOpenBrand} closeModal={() => toggleBrandModal()} />
        <AddCategory
          isOpen={isOpenCategory}
          closeModal={() => toggleCategoryModal()}
        />
        <AddWarranty
          warranty={{}}
          isOpen={openWarrantyModal}
          closeModal={() => toggleWarrantyModal()}
        />
        {isOpenDelete && (
          <DeleteModal
            closeModal={() => setIsOpenDelete(false)}
            onProductDelete={() => onProductDelete()}
          />
        )}
      </div>
    </Modal>
  );
};
export default connect(
  (state: any) => ({
    tags: state.product.get('tags'),
    brands: state.product.get('brands'),
    imageId: state.product.get('imageId'),
    menuImage: state.product.get('menuImage'),
    categories: state.product.get('categories'),
    warranties: state.warranty.get('warranties'),
    currentUser: state.setting.get('currentUser'),
    loadingAction: state.common.get('loadingAction'),
    selectedShop: state.dashboard.get('selectedShop'),
  }),
  {
    setImage: Actions.product.setImage,
    uploadImage: Actions.product.uploadImage,
    removeImage: Actions.product.removeImage,
    createProduct: Actions.product.createProduct,
    updateProduct: Actions.product.updateProduct,
    deleteProduct: Actions.product.deleteProduct,
    fetchProductOptionSets: Actions.product.fetchProductOptionSets,
    fetchProductTags: Actions.product.fetchProductTags,
  }
)(AddProduct);
