import { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../Button";
import Modal from "react-modal";
import { nanoid } from "nanoid";
import { isEmpty, kebabCase } from "lodash";
import "../../layouts/Styles.css";
import { connect } from "react-redux";
import FormInput from "../inputs/FormInput";
import { AiOutlineClose } from "react-icons/ai";
import FormTextarea from "../inputs/FormTextarea";
import { Field, Formik, useFormikContext } from "formik";
import { Actions } from "../../internals/app/Actions";
import { mapCategory } from "../../internals/managers/ProductManager";
import CustomSelect from "../inputs/CustomSelect";

const customStyles: any = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    position: "fixed",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
  content: {
    padding: 0,
    height: "auto",
    border: "none",
    overflow: "auto",
    maxWidth: "624px",
    background: "none",
    top: "calc(50vh - 20rem)",
    left: "calc(50vw - 292px)",
    WebkitOverflowScrolling: "touch",
  },
};

type CategoryProps = {
  category?: any;
  isOpen: boolean;
  isEdit?: boolean;
  currentUser: any;
  selectedShop: any;
  closeModal: () => void;
  createCategory: (params: any) => void;
  updateCategory: (params: any) => void;
  categories: any[];
};

const AddCategory = (props: CategoryProps) => {
  const {
    isOpen,
    category,
    closeModal,
    currentUser,
    selectedShop,
    createCategory,
    updateCategory,
    categories,
  } = props;
  const [isEdit, setIsEdit] = useState<boolean>(false);

  useEffect(() => {
    setIsEdit(!isEmpty(category));
  }, [category]);

  const onCreateCategory = (values: {
    categoryName: string;
    description: string;
    categorySlug: string;
    parent: string;
  }) => {
    const param = {
      categoryId: nanoid(),
      createdTime: Date.now(),
      updatedTime: Date.now(),
      shopId: selectedShop.shopId,
      createdUserId: currentUser.uid,
      description: values.description,
      categoryName: values.categoryName,
      parent: values.parent,
      categorySlug: values.categorySlug,
    };
    createCategory(param);
    closeModal();
  };

  const onUpdateCategory = (values: {
    categoryName: string;
    description: string;
    categorySlug: string;
    parent: string;
  }) => {
    const param = {
      updatedTime: Date.now(),
      shopId: category.shopId,
      createdUserId: category.uid,
      categoryId: category.categoryId,
      description: values.description,
      categoryName: values.categoryName,
      categorySlug: values.categorySlug,
      parent: values.parent,
      createdTime: category.createdTime,
    };
    updateCategory(param);
    closeModal();
  };

  const validationSchema = Yup.object({
    categoryName: Yup.string()
      .min(2, "Must be 2 characters or more")
      .required("Please enter category name"),
    categorySlug: Yup.string()
      .min(2, "Must be 2 characters or more")
      .required("Please enter category slug"),
  });

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Category Modal"
      onRequestClose={() => closeModal()}
    >
      <div className=" bg-white shadow rounded-lg">
        <div className="flex flex-row justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-lg">
          <h3>{isEdit ? "Edit Category" : "Add Category"}</h3>
          <button
            type="button"
            className="top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
            onClick={() => closeModal()}
          >
            <AiOutlineClose size={20} />
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            categoryName: "",
            description: "",
            categorySlug: "",
            parent: "",
          }}
          onSubmit={(values) => {
            if (!isEdit) {
              onCreateCategory(values);
            } else {
              onUpdateCategory(values);
            }
          }}
        >
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="h-auto bg-white px-4 py-5 sm:p-6 rounded-lg">
                  <RenderInput {...{ category, categories }} />
                </div>
                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-lg">
                  <Button
                    type="submit"
                    label={isEdit ? "Update Category" : "Add Category"}
                  />
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default connect(
  (state: any) => ({
    currentUser: state.setting.get("currentUser"),
    selectedShop: state.dashboard.get("selectedShop"),
    categories: state.product.get("categories"),
  }),
  {
    createCategory: Actions.product.createCategory,
    updateCategory: Actions.product.updateCategory,
  }
)(AddCategory);

const RenderInput = ({ category, categories }: any) => {
  const { values, errors, touched, handleChange, setFieldValue } =
    useFormikContext<{
      categoryName: string;
      categorySlug: string;
      description: string;
      parent: string;
    }>();

  useEffect(() => {
    if (!isEmpty(category)) {
      setFieldValue("categoryName", category.categoryName);
      setFieldValue("categorySlug", category.categorySlug);
      setFieldValue("description", category.description);
      setFieldValue("parent", category.parent);
    }
  }, [category]);

  useEffect(() => {
    if (isEmpty(values.categorySlug)) {
      setFieldValue("categorySlug", kebabCase(values.categoryName));
    }
  }, [values]);

  const categoryList = mapCategory(categories);

  return (
    <div className="grid grid-cols-6 gap-6">
      <div className="col-span-6 sm:col-span-6">
        <FormInput
          id="categoryName"
          label="Category name"
          placeholder="Category name"
          error={errors.categoryName}
          value={values.categoryName}
          touched={touched.categoryName}
          onChange={handleChange("categoryName")}
        />
      </div>
      <div className="col-span-6 sm:col-span-6">
        <FormInput
          id="categorySlug"
          label="Category slug"
          placeholder="Without spaces"
          error={errors.categorySlug}
          value={values.categorySlug}
          touched={touched.categorySlug}
          onChange={handleChange("categorySlug")}
        />
      </div>
      <div className="col-span-6 sm:col-span-6">
        <Field
          id="parent"
          name="parent"
          isMulti={false}
          label="Parent Category"
          options={categoryList}
          error={errors.parent}
          component={CustomSelect}
          touched={touched.parent}
          placeholder="Select category"
          // buttonOption={" Category"}
          // onClickOption={() => openCategory()}
        />
      </div>
      <div className="col-span-6 sm:col-span-6">
        <FormTextarea
          id="description"
          label="Description"
          error={errors.description}
          value={values.description}
          touched={touched.description}
          onChange={handleChange("description")}
        />
      </div>
    </div>
  );
};
